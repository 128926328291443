<template>
	<div class="specimen">
		<e-table ref="tables" v-on:resetSearch="resetSearch" @query-Table-data="handleCurrentChange" v-on:searchParamsChange="searchParamsChange" :total="total" :currentPage="currentPage" :condition="form" :tableCols="specimenTableConfComputed" :customSearchList="searchFormList" :showOperation="true" :dataOrigin="dataOrigin">
			<template #account2="scope">
				<el-popover
					trigger="hover">
					<img height="300" width="300" :src="scope.rowData.pict_url" alt="">
					<img slot="reference" height="80" width="80" :src="scope.rowData.pict_url" alt="">
				</el-popover>
			</template>
			<template #title="scope">
				<el-link class="lh_12" type="primary" :href="scope.rowData.item_url" target="_blank">{{ scope.rowData.title }}</el-link>
			</template>
			<template #expandSearchParams>
				<el-col :span="6" class="flex_r f_ai_c">
					<span class="w_75">商品分类</span>
					<el-select v-model="form.cat" placeholder="商品分类">
						<el-option :label="cate.name" :value="cate.id"  v-for="cate in category" :key="cate.index"></el-option>
					</el-select>
				</el-col>
			</template>
			<template #customOperation>
<!--				<el-col :span="1">-->
<!--					<el-button @click="$refs.dialogRef.handleOpen()">新增样品</el-button>-->
<!--				</el-col>-->
				<el-col :span="24">
					<el-radio-group @change="agreeChange" v-model="tabPosition" style="margin-bottom: 30px;">
						<el-radio-button label="1">待寄件</el-radio-button>
						<el-radio-button label="2">待收货</el-radio-button>
						<el-radio-button label="3">已入库</el-radio-button>
						<el-radio-button label="4">已寄回</el-radio-button>
						<el-radio-button label="5">已完结</el-radio-button>
					</el-radio-group>
				</el-col>
			</template>
		</e-table>
		<e-dialog title="新增样品" ref="dialogRef">
			<e-form :formConfList="formConfList" />
		</e-dialog>
	</div>
</template>

<script>
import {specimenTableConf, searchFormList, formConfList} from '@data/specimen'
export default {
	name: 'Specimen',
	data() {
		return {
			specimenTableConf,
			searchFormList,
			formConfList,
			dataOrigin: {
				data: []
			},
			tabPosition: 1,
			total: 1,
			currentPage: 1,
			activeName: "first",
			form: {},
			condition: {},
			category: []
		}
	},
	computed: {
		specimenTableConfComputed() {
			return this.specimenTableConf[this.tabPosition]
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetLists()
		},
		agreeChange(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetLists()
		},
		resetSearch(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetLists()
		},
		searchParamsChange(e) {
			this.$refs.tables.loading = true
			this.condition = e.condition
			this.currentPage = 1
			this.GetLists()
		},
		handleClick() {},
		GetLists(){
			let that = this
			this.dataOrigin.data = []
			let formData = this.$refs.tables.condition
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			that.$refs.tables && that.$refs.tables.initData()
			// console.log(that.dataOrigin)
			this.get('?server=live.goods.specimen.lists.get',{
				type: this.tabPosition,
				page:this.currentPage,
				zbtime: formData.zbtime,
				shop_name: formData.nick,
				cat: this.form.cat,
				sname: formData.sname,
				query:formData.query}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)
				let len = response.data.lists.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.lists.data[i])
				}
				that.category = response.data.category
				that.total = response.data.lists.total
				that.$refs.tables.total = response.data.lists.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
				// that.dataOrigin.data = response.data.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
			this.GetLists()
		});
	}
}
</script>