const baseTableConf = [
	{
		label: '直播日期',
		prop: 'zbtime',
		search: true,
		type: 'date'
	},
	{
		label: '商品图片',
		prop: 'account2'
	},
	{
		label: '商品名称',
		prop: 'title',
		search: true,
		type: 'input'
	},
	{
		label: '商家名称',
		prop: 'sname',
		search: true,
		type: 'input'
	},
	{
		label: '店铺名称',
		prop: 'nick',
		search: true,
		type: 'input'
	},
	{
		label: '商品类目',
		prop: 'name',
		search: false,
		type: 'select',
		options: []
	},
]
export const specimenTableConf = {
	1: [
		...baseTableConf,
		{
			label: '备注',
			prop: 'memo'
		},
		{
			label: '寄送地址',
			prop: 'account10'
		}
	],
	2: [
		...baseTableConf,
		{
			label: '寄件快递号',
			prop: 'kd'
		},
		{
			label: '备注',
			prop: 'memo'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	],
	3: [
		...baseTableConf,
		{
			label: '备注',
			prop: 'memo'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	],
	4: [
		...baseTableConf,
		{
			label: '退样快递号',
			prop: 'back_num'
		},
		{
			label: '备注',
			prop: 'memo'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	],
	5: [
		...baseTableConf,
		{
			label: '备注',
			prop: 'memo'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	],
	6: [
		{
			label: '收件日期',
			prop: 'account1',
		},
		{
			label: '商品名称',
			prop: 'account3',
			search: true,
			type: 'input'
		},
		{
			label: '商品类目',
			prop: 'account7',
			search: true,
			type: 'select',
			options: []
		},
		{
			label: '快递信息',
			prop: 'account12'
		},
		{
			label: '备注',
			prop: 'account11'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	]
}

export const searchFormList = [
	{
		label: '快递单号',
		prop: 'kd',
		type: 'input'
	}
]

export const formConfList = [
	{
		label: '商品名称',
		prop: 'a',
		type: 'input'
	},
	{
		label: '商品类目',
		prop: 'b',
		type: 'select',
		options: []
	},
	{
		label: '快递公司',
		prop: 'c',
		type: 'select',
		options: []
	},
	{
		label: '快递单号',
		prop: 'd',
		type: 'input'
	},
	{
		label: '样品明细',
		prop: 'f',
		type: 'input'
	},
	{
		label: '备注',
		prop: 'g',
		type: 'input'
	}
]